let tryLayer = document.querySelector('.layer.location-cards');
var navigateToLocationCardLink = null;
// only create this method on pages with at least one location-cards layer
if (tryLayer) {
    navigateToLocationCardLink = function navigateToLocationCardLink(p,e) {
        let target = e.target;
        let cardLink = p.querySelector('a').href;
        
        // remove window location from href
        if (cardLink.startsWith(window.location.href)) {
            // PREFIX is exactly at the beginning
            cardLink = cardLink.slice(window.location.href.length);
        }
        
        if (cardLink && !target.classList.contains('card-phone_number')) {
            window.location.href = cardLink;
        }
    }
}
