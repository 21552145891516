/*jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){

        this.addEventListener("mousewheel", handle, { passive: false });

    }
  };*/

(function () {
    let $ = jQuery;
    var $contactMenu = $('.contact-menu');
    var enableScrolljacking = true;
    var $tabsSection = $('#tabs-section');
    const slider = $("#slick");

    if ($tabsSection.length > 0) {
        var body = $('body');
        var sections = $('.scrollify');
        var isAnimating = false;
        var initialSection = sections.eq(0);
        var lastSection = sections.eq(sections.length - 1);
        var currentSection = initialSection;
        var doFirst = true;
        var doLast = false;
        var $tabRow = $tabsSection.find('.tab-row');
        var $topBar = $tabsSection.find('.top-bar');
        var stickHeader = true;

        // initScrolljackingEnable();
        initSlick();
        initClickable();
    }

    function initSlick() {
        slider.on('init', () => {
           // mouseWheel(slider)
        }).slick({
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"> < </button>',
            nextArrow: '<button type="button" class="slick-next"> > </button>',
            responsive: [{
                breakpoint: 768,
                settings: "unslick"
              }]
        });

        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $tabRow.find('a').removeClass("active");
            $tabRow.find('li').eq(nextSlide).find('a').addClass("active");
        });
    }

    function initClickable() {
        $tabsSection.find('.top-bar a').on('click', function () {
            var $id = $(this).data('id') - 1;
            slider.slick('slickGoTo',$id,false);
            $tabRow.find('a').removeClass("active");
            $(this).addClass("active");
        });
    }

/*
    function mouseWheel(slider) {
        $(window).on('mousewheel', { slider: slider }, mouseWheelHandler);
    }
    function mouseWheelHandler(event) {
        const slider = event.data.slider;
        if(slider.slick('slickCurrentSlide') == 0){
        event.preventDefault();
        }


        const delta = event.originalEvent.deltaY;
        
        if (delta > 0) {
            slider.slick('slickPrev');
        }
        else {
            slider.slick('slickNext');
        }
    }*/


/*
    function scrolljacking(e) {
        if (enableScrolljacking) {
            e = e || window.event;
            var value = e.originalEvent.wheelDelta || -e.originalEvent.deltaY || -e.originalEvent.detail;
            var delta = Math.max(-1, Math.min(1, value));
            var scrollDirection = delta < 0 ? "down" : "up";
            if (!isAnimating && !body.hasClass("no-scroll")) {
                if ($('#tabs-section').is(':in-viewport(' + window.innerHeight / 3 + ')')) {
                    if (scrollDirection == "up") {
                        if (doLast) {
                            doScroll(currentSection, scrollDirection);
                            doLast = false;
                            stickHeader = true;
                            if (stickHeader) {
                                setTimeout(function () {
                                    $topBar.removeClass('sticky');
                                    $topBar.css("top", 0);
                                }, 300);
                            }
                            return;
                        }
                        var prev = currentSection.prev(".scrollify");
                        if (prev.length == 0) {
                            doFirst = true;
                            currentSection = initialSection;
                            $tabsSection.removeClass("fixed");
                            $topBar.removeAttr("style");
                            return;
                        } else {
                            doScroll(prev, scrollDirection);
                        }
                    } else {
                        if (doFirst) {
                            doScroll(currentSection, scrollDirection);
                            setTimeout(function () {
                                $tabsSection.addClass("fixed");
                            }, 300)
                            doFirst = false;
                        } else {
                            var next = currentSection.next(".scrollify");
                            if (next.length == 0) {
                                currentSection = lastSection;
                                doLast = true;
                                if (doLast && stickHeader) {
                                    $topBar.addClass('sticky');
                                    $topBar.css("top", lastSection.position().top);
                                    stickHeader = false;
                                }
                                return;
                            } else {
                                doScroll(next, scrollDirection);
                            }
                        }
                    }
                }
            }
        }
    }

    function doScroll(selector) {
        isAnimating = true;
        var index = sections.index(selector);
        $tabRow.find('a').removeClass("active");
        $tabRow.find('li').eq(index).find('a').addClass("active");
    }

    function hideScrollingIcon() {
        if ($('.cards').is(':in-viewport')) {
            $('#scroll-more').fadeOut();
        } else {
            $('#scroll-more').fadeIn();
        }
    }

    function initScrolljackingEnable() {
        var setScrolljackStatus = function () {
            if ($contactMenu.css('max-width') !== "320px") {
                enableScrolljacking = true;
            } else {
                $topBar.removeAttr("style");
                $topBar.removeClass("sticky");
                $tabsSection.removeClass("fixed");
                enableScrolljacking = false;
            }
        }

        //page load
        setScrolljackStatus();
        var disabledScrollJackingonResize = debounce(function () {
            setScrolljackStatus();
        }, 400);
        window.addEventListener('resize', disabledScrollJackingonResize);
        $(window).on('mousewheel DOMMouseScroll', throttle(scrolljacking, 200));
        $(window).on('scroll', throttle(hideScrollingIcon, 350));
    }
    */
})();